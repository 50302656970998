
import Modal from "@/components/base/modal/Modal"
import Button from "@/components/base/Button"
import ValidationResponse from "@/components/base/api/ValidationResponse"
import { mdiCloudUploadOutline, mdiCheck, mdiPlus, mdiClose } from "@mdi/js"
import adminService from "@/services/admin.service"
import Checkbox from "@/components/base/input/Checkbox"

export default {
    name: "ChannelBulkAddModal",
    components: { Checkbox, ValidationResponse, Modal, Button },
    props: {
        channelId: {
            type: Number,
            default: null,
        },
        showBulkAddModal: {
            type: Boolean,
            default: false,
        },
    },
    icons: {
        mdiCloudUploadOutline,
        mdiCheck,
        mdiPlus,
        mdiClose,
    },
    data: function () {
        return {
            headers: [
                { text: "Name (CSV)", value: "origin", align: "left" },
                { text: "Name", value: "name", align: "left" },
                { text: "Stadt", value: "city", align: "left" },
                {
                    text: "Übereinstimmung",
                    value: "percentage",
                },
                {
                    text: "Channel",
                    value: "isInChannel",
                },
            ],
            results: [],
            channelSlug: null,
            loading: false,
            validationData: null,
            file: null,
            hideMatched: true,
        }
    },
    computed: {
        showModal: {
            // separata property, for avoiding mutating given showStatisticsModal property directly
            get: function () {
                return this.showBulkAddModal
            },
            set: function () {},
        },
        flattenedResults() {
            return this.results?.map(function (e) {
                return e[0]
            })
        },
    },
    watch: {
        showBulkAddModal(isShown) {
            if (!isShown) {
                this.$emit("close")
            }
        },
    },
    methods: {
        rowStyleClass(item) {
            if (item.isInChannel && this.hideMatched) {
                return "bulk-add-modal__hidden"
            }
            if (item.isInChannel) {
                return "bulk-add-modal__matched"
            }
            if (!item.percentage) {
                return ""
            }
            if (item.percentage === 100) {
                return "bulk-add-modal__perfect"
            }
            if (item.percentage < 100 && item.percentage >= 90) {
                return "bulk-add-modal__good"
            }
            if (item.percentage < 90 && item.percentage >= 80) {
                return "bulk-add-modal__fair"
            }
            if (item.percentage < 80 && item.percentage >= 70) {
                return "bulk-add-modal__bad"
            }
            return "bulk-add-modal__worst"
        },
        formattedNumber(number) {
            return number?.toFixed(2)
        },
        setFile(file) {
            this.file = file
        },
        matchFileWithBusinessSites() {
            const data = new FormData()
            data.append("file", this.file)
            data.append("channel_id", this.channelId)
            this.$axios.$post("/api/v2/business/match", data).then((r) => {
                this.results = r.results
                this.channelSlug = r.slug
                this.file = null
            })
        },

        async addBusinessSiteToChannel(businessSiteEntry) {
            this.loading = true
            await adminService.toggleBusinessSiteChannelVisibility(
                businessSiteEntry.slug,
                true,
                this.channelSlug
            )
            businessSiteEntry.isInChannel = true
            this.loading = false
        },
    },
}
