
import { mdiCheck } from "@mdi/js"

export default {
    name: "Checkbox",
    props: {
        // Boolean to show or hide the icon
        value: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        // Material design icon to show in the checkbox if selected
        icon: {
            type: String,
            default: mdiCheck,
        },
        iconColor: {
            type: String,
            default: "success",
        },
        // Boolean to show loading animation
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            mdiCheck: mdiCheck,
        }
    },
}
