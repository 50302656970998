
import pagination from "@/mixins/pagination"

import Button from "@/components/base/Button"
import { mdiOpenInNew, mdiMessage, mdiFile } from "@mdi/js"
import ImpersonateUser from "@/components/admin/ImpersonateUser"
import RegionSearch from "@/components/region/RegionSearch"
import RegionStatisticsItem from "@/components/admin/business/utils/RegionStatisticsItem"
import dates from "@/mixins/dates"
import ChannelBulkAddModal from "@/components/admin/business/utils/ChannelBulkAddModal"

export default {
    name: "AdminRegionList",
    components: {
        ChannelBulkAddModal,
        RegionStatisticsItem,
        RegionSearch,
        ImpersonateUser,
        Button,
    },
    mixins: [pagination, dates],
    data: function () {
        return {
            paginationUrl: "/api/oabalytics/regions",
            search: "",
            selectedRegion: null,
            bulkAddChannelId: null,
            mdiOpenInNew,
            mdiMessage,
            mdiFile,
        }
    },
    computed: {
        headers() {
            let header = [
                {
                    text: "Region",
                    value: "name",
                    sortable: false,
                },
                {
                    text: "BC-Score (Ø 7-Tage)",
                    value: "business_conversation_score",
                    sortable: false,
                },
                {
                    text: "#Letzte Aktivität",
                    value: "latest_page_view_at",
                },
            ]
            if (this.$breakpoint.mdAndUp) {
                header.push(
                    {
                        text: "#Sessions",
                        value: "sessions_count",
                        width: "30px",
                    },
                    {
                        text: "#Firmen in der Region",
                        value: "businesses_count",
                        width: "30px",
                        sortable: false,
                    },
                    {
                        text: "#Schulen in der Region",
                        value: "schools_count",
                        width: "30px",
                    },
                    {
                        text: "#Besucher",
                        value: "visitors_count",
                        sortable: false,
                    },
                    {
                        text: "Aktionen",
                        value: "megabat",
                        sortable: false,
                        align: "right",
                    }
                )
            }
            return header
        },
    },
    watch: {
        search(val) {
            this.loadPagination(this.paginationUrl, { region_ids: val })
        },
    },
    methods: {
        selectRegion(row) {
            if (this.$breakpoint.smAndDown) {
                this.selectedRegion = row
            }
        },
    },
}
