
import Button from "@/components/base/Button"
import dates from "@/mixins/dates"
import { routeToMegabat } from "@/helpers/routing"

export default {
    name: "RegionStatisticsItem",
    components: {
        Button,
    },
    mixins: [dates],
    props: {
        region: {
            type: Object,
            required: true,
        },
    },
    computed: {
        statisticsData() {
            return [
                { label: "Anzahl Besucher", value: this.region.visitors_count },
                {
                    label: " Anzahl Sessions",
                    value: this.region.sessions_count,
                },
            ]
        },
    },
    methods: {
        toMegabat() {
            this.$router.push(routeToMegabat(this.region.slug))
        },
    },
}
