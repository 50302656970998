
export default {
    name: "RegionSearch",
    data: () => ({
        searchError: null,
        regions: [],
    }),
    mounted() {
        this.loadRegions()
    },
    methods: {
        async loadRegions() {
            let { data } = await this.$axios
                .$get("/api/regions")
                .catch((err) => {
                    console.warn(err)
                    this.searchError = err
                })
            this.regions = data
        },
    },
}
