const pagination = {
    data() {
        return {
            searchParams: [],
            paginationMeta: {
                links: {},
                meta: {},
                error: {},
            },
            paginationUrl: "",
            paginationData: [],
            paginationOptions: {},
            paginationLoading: true,
        }
    },
    watch: {
        paginationOptions: {
            handler() {
                this.loadPagination()
            },
            deep: true,
        },
    },
    methods: {
        async loadPagination(url = this.paginationUrl, additionalOptions = {}) {
            if (!url) return
            this.paginationUrl = url
            this.paginationLoading = true
            const { sortBy, sortDesc, page, itemsPerPage } =
                this.paginationOptions

            let { data, meta, links } = await this.$axios
                .$get(url, {
                    params: {
                        page: page,
                        order_by: sortBy?.[0]?.replace(".", "_"),
                        order_by_desc: sortDesc?.[0],
                        per_page: itemsPerPage,
                        ...additionalOptions,
                        ...this.searchParams,
                    },
                })
                .catch((err) => {
                    console.warn(err)
                    this.paginationMeta.error = err
                })
            this.paginationLoading = false
            this.paginationData = data
            this.paginationMeta.meta = meta
            this.paginationMeta.links = links
            return data
        },
    },
}
export default pagination
